<template>
  <div>
    <el-dialog title="选择参与活动的员工" :visible="choosestaffshow" width="70%" @close="closestaff" custom-class="staffpadding">
      <div class = "flexRow" style="flex-wrap:wrap">
        <div class=  "flexRow">
          <div class = "conditionsFont">关键字：</div>
          <el-input style="width:200px" v-model="staffkeywords" placeholder="员工姓名、手机号"></el-input>
        </div>
        <div class=  "flexRow" style="margin:0px 10px">
          <div class = "conditionsFont">归属门店：</div>
          <el-select v-model="stores" style="width:200px" placeholder="请选择归属门店">
            <el-option :value="null" label="全部"></el-option>
            <el-option v-for="(v,i) in storeslist" :key="i" :value="v.Id" :label="v.ShopName"></el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="querystaff">查询</el-button>
      </div>
      <div style="margin-top:10px">
        <el-table :data="stafftablelist" v-loading="stafftableloading" ref="stafftable" max-height="500px" :row-key="rowKeys" @selection-change = "selectionChange">
          <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
          <el-table-column prop="EmployeeName" label="员工" width="400px">
            <template slot-scope="scope">
              <div class = "flexRow rowbetween">
                <img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl:'https://cdn.dkycn.cn/images/melyshop/pcDefaultHeader.png'" style="width:60px;height:60px;border-radius:3px" alt="">
                  <div class = "vertical2" style="width:330px;margin-left:5px">
                    {{scope.row.EmployeeName}}
                  </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Phone" label="手机号"></el-table-column>
          <el-table-column prop="ShopName" label="归属门店"></el-table-column>
        </el-table>
      </div>
      <div style="margin-top:10px" class="flexRow colbetween">
        <el-checkbox :value="staffallcheck" @change="allcheckchange" style="margin-left:15px">当前页全选</el-checkbox>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="sizepage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div style="text-align:center;margin-top:20px">
        已选中 <span style="color:rgb(245,108,108)">{{staffallchecklist.length}}</span> 个
        <el-button type="primary" style="width:90px;height:36px;margin-left:10px" :loading="btnloading" @click="staffcomfire">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  shopdroplist
} from "@/api/api"
import {
  qyweixinqyweixinfriendfissionactivitychoseemployeelist,
} from "@/api/sv1.0.0"
export default {
  props:{
    choosestaffshow:{
      type:Boolean,
      default:false,
    },
    chooseList:{
      type:Array,
      default:()=>{
        return []
      },
    },
    state:{
      type:Number,
      default:0,
    }
  },
  watch:{
    choosestaffshow(val){
      if(val){
        this.getshopdroplist()
        this.getstafftablelist()
        this.staffmay = true
      }
    }
  },
  computed: {
    staffallcheck(){
      if(this.stafftablelist&&this.stafftablelist.length){
        return this.stafftablelist.every((v)=>{
          return this.staffallchecklist.some((x)=>{
            return x.Id==v.Id
          })
        })
      }else{
        return false
      }
    },
  },
  data () {
    return {
      staffkeywords:'',
      stores:null,
      storeslist:[],
      stafftablelist:[],
      stafftableloading:false,
      currentPage:1,
      sizepage:20,
      total:null,
      staffmay:false,
      staffallchecklist:[],
      btnloading:false,
    }
  },
  methods:{
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.getstafftablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.getstafftablelist()
    },
    staffcomfire(){
      this.btnloading=true
        // console.log(this.staffallchecklist,111)
      if(this.state==3){
        this.$message.error('活动已结束，员工修改失败')
      }else if(this.staffallchecklist.length>100){
        this.$message.error('每场活动最多选择100个员工')
      }else if(this.staffallchecklist.length<=0){
        this.$message.error('请选择员工')
      }else{
        this.$emit('getChooseList',this.staffallchecklist)
        this.closestaff()
      }
      setTimeout(()=>{
        this.btnloading = false
      },1000)
    },
    allcheckchange(e){
      this.$refs.stafftable.toggleAllSelection()
      if(this.staffallchecklist.length>100){
        this.$message.error('每场活动最多选择100个员工')
      }
    },
    selectionChange(e){
      this.staffallchecklist = e
      if(e.length>100){
        this.$message.error('每场活动最多选择100个员工')
      }
    },
    rowKeys(row) {
			return row.Id
		},
    querystaff(){
      this.currentPage = 1
      this.getstafftablelist()
    },
    //门店列表
    async getshopdroplist(){
      const res = await shopdroplist({
					IsOpen: true
				});
				if (res.IsSuccess) {
          // console.log(res.Result)
					this.storeslist = res.Result;
				}
    }, 
    //获取员工列表
    async getstafftablelist(){
      this.stafftableloading = true
      try{
        let data = {
          Id:this.$route.query.Id,
          Keywords:this.staffkeywords,
          ShopId:this.stores
        }
        let result = await qyweixinqyweixinfriendfissionactivitychoseemployeelist(data)
        if(result.IsSuccess){
          // console.log(result.Result)
          this.allstafftablelist = result.Result
          this.total = this.allstafftablelist.length
          this.stafftablelist = this.allstafftablelist.filter((v,i)=>{
            return i>=(this.currentPage-1)*this.sizepage && i<this.currentPage*this.sizepage
          })
          if(this.staffmay){
            this.$nextTick(()=>{
              this.allstafftablelist.map((v)=>{
                this.chooseList.map((x)=>{
                  if(x.Id==v.Id){
                    this.$refs.stafftable.toggleRowSelection(v,true)
                  }
                })
              })
            this.staffmay = false
            })
          }
        }
      }finally{
        this.stafftableloading = false
      }
      
    },
    closestaff(){
      // this.choosestaffshow = false
      this.staffkeywords = ''
      this.stores = null
      this.currentPage = 1
      this.sizepage = 10
      this.staffmay = false
      this.$refs.stafftable.clearSelection()
      this.$emit('close')
    },
  }
}
</script>

<style lang = "less" scoped>
.flexRow{
  display: flex;
  align-items: center;
}
  .conditionsFont{
    font-size: 14px;
    line-height: 36px;
    font-weight: bold;
    color: #606266;
  }
  .colbetween{
    justify-content: space-between;
  }
</style>