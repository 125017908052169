<template>
  <div v-loading="pageloading">
    <el-form :model="ruform" label-width="140px" :rules="rules" ref="ruform" style="margin-bottom:80px">
      <el-card>
        <div class = "titFont">基本信息</div>
        <div style="margin-top:20px">
          <el-form-item label="活动名称：" prop="ActivityName">
            <el-input :disabled="state==3" v-model="ruform.ActivityName" style="width:400px" placeholder="请输入活动名称，最多20个字" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="活动时间：" prop="ActivityEndTime" class ="haveStar">
            <div class = "flexRow">
              <el-date-picker :disabled="state==2||state==3" v-model="ruform.ActivityStartTime" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
               type="datetime" placeholder="开始时间" :picker-options="pickerOptions"></el-date-picker>
              <span style="margin:0px 10px">~</span>
              <el-date-picker :disabled="state==3" v-model="ruform.ActivityEndTime" value-format="yyyy-MM-dd HH:mm:ss"
               format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="结束时间" :picker-options="pickerOptions"></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="参与活动员工：" prop="EmployeeList" >
            <div class = "flexRow" v-if="ruform.EmployeeList&&ruform.EmployeeList.length">
              <div>
                <el-tag v-for="(v,i) in ruform.EmployeeList" :key="i" v-if="i<4" type="info" effect="plain" style="margin:5px 10px 0px 0px"
                  :class="{Employeetag:v.WxHeadUrl}">
                  <div class="flexRow flexcontent">
                    <div v-if="v.WxHeadUrl" style="width:20px;height:20px;margin-right:5px">
                      <img :src="v.WxHeadUrl" style="width:100%;height:100%" alt="" />
                    </div>
                    <div v-else class = "flexRow flexcontent" style="width:20px;height:20px;margin-right:5px">
                      <i class = "el-icon-user"></i>
                    </div>
                    {{v.EmployeeName}}
                  </div>
                </el-tag>
              </div>
              <!-- <i v-if="state==3" class = "el-icon-arrow-down" style="margin:5px 5px 0px 0px;cursor:pointer"></i> -->
              <i class = "el-icon-arrow-down" style="margin:5px 5px 0px 0px;cursor:pointer" @click="choosestaffshow=true"></i>
              <el-button  type="text" @click="choosestaffshow=true">{{state==3?'查看':'修改'}}参与活动员工</el-button>
            </div>
            <div v-else>
              <el-button :disabled="state==3" type="text" @click="choosestaffshow=true">选择员工</el-button>
            </div>
            <div style="margin-top:-5px;height:40px">
              <p style="font-size:12px;color:#909399;line-height:1.5;">客户在商城内点击配置了活码的入口时，企业微信将推送已选中员工的企业微信二维码。根据企业微信规定，每次最多选择100个员工</p>
              <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnt8DZhXslOu9KrdmNE2oLDh" target="_blank" type="primary" class = "linkFont">
                有的员工在员工管理列表里存在，为什么在活动里查询不到？
              </el-link>
            </div>
          </el-form-item>
          <el-form-item label="目标涨粉人数：" prop="RiseFansTarget">
            <el-input :disabled="state==3" v-model="ruform.RiseFansTarget" style="width:200px"></el-input>
            <div class ="grayFont"></div>
          </el-form-item>
        </div>
      </el-card>
      <el-card style="margin-top:10px">
        <div class = "titFont">活动权益</div>
        <div style="margin-top:20px">
          <el-form-item label="活动权益：">
            <el-switch :disabled="state==3" v-model="ruform.IsOpenActivityRights"></el-switch>
            <div>
              <div class = "grayFont">1.开启后，客户通过活动二维码，成功添加员工企业微信，可获得优惠券或参与转盘抽奖活动</div>
              <div class = "grayFont">2.每场活动每个客户ID仅赠送1次，商家可配置赠送数量</div>
            </div>
          </el-form-item>
          <el-form-item v-if="ruform.IsOpenActivityRights" prop="ActivityRightsType">
            <el-radio-group v-model="ruform.ActivityRightsType" style="width:100%" @change="ActivityRightsTypeChange" :disabled="state==3">
                <el-form-item key="ActivityRightsType1" :rules="ruform.ActivityRightsType==1?rules.QyWeixinRiseFansMarketingActivityRightsList:[{required:false}]"
                 prop="QyWeixinRiseFansMarketingActivityRightsList" style="margin-bottom:20px">
                  <el-radio :label="1">赠送优惠券</el-radio>
                  <el-button type="text" v-if="ruform.ActivityRightsType==1&&(state==0 || state==1 || state==2)" @click="couponsdialogshow=true">选择优惠券</el-button>
                  <div class = "grayFont" v-if="ruform.ActivityRightsType==1" style="margin-left:25px">若优惠券剩余数量小于赠送数量，优惠券将赠送失败。请关注优惠券剩余数量并及时补充。</div>
                  <div style="margin-top:20px" v-if="ruform.ActivityRightsType==1">
                    <couponstable :disabled="state==3" ref="couponstable" :list="ruform.QyWeixinRiseFansMarketingActivityRightsList" @delshop="delshop"></couponstable>
                  </div>
                </el-form-item>
                <el-form-item key="ActivityRightsType2" :rules="ruform.ActivityRightsType==2?rules.QyWeixinRiseFansMarketingActivityRightsList:[{required:false}]"
                 prop="QyWeixinRiseFansMarketingActivityRightsList" style="margin-bottom:20px">
                  <el-radio :label="2">赠送抽奖次数</el-radio>
                  <el-button type="text" v-if="ruform.ActivityRightsType==2&&(state==0 || state==1 || state==2)" @click="selectactiveDialog=true">
                    {{ruform.QyWeixinRiseFansMarketingActivityRightsList.length?'重新选择抽奖活动':'选择抽奖活动'}}
                  </el-button>
                  <div>
                    <div style="margin-top:20px;" v-if="ruform.ActivityRightsType==2">
                      <activityTable :disabled="state==3" style="margin-left:-20px" ref="activityTable" :GiftdetailData="ruform.QyWeixinRiseFansMarketingActivityRightsList"></activityTable>
                    </div>
                  </div>
                </el-form-item>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-card>
      <el-card style="margin-top:10px">
        <div class = "titFont">活动欢迎语</div>
        <div style="margin-top:20px">
          <el-form-item label="活动欢迎语：">
            <el-switch v-model="ruform.IsOpenWelcome" :disabled="state==3"></el-switch>
            <div>
              <div class = "grayFont">1.开启后，客户通过该活动二维码添加员工企业微信，将会发送此处配置的欢迎语</div>
              <div class = "grayFont">2.若关闭，将发送好友欢迎语中，员工适用的欢迎语</div>
            </div>
          </el-form-item>
          <div v-if="ruform.IsOpenWelcome" style="display:flex;align-items:flex-start">
            <el-form-item label="文本内容：" prop="Welcome" class="haveStar" style="max-width:800px;flex:1">
              <div>
                <div>
                  <div class="edit-wraper">
                    <div class="title">
                      <div style="margin-right: 20px;">
                        <el-link type="primary" @click="insertWxNickName">插入客户微信昵称</el-link>
                      </div>
                      <div>
                        <el-link type="primary" @click="insertEmployeeName">插入员工姓名</el-link>
                      </div>
                    </div>
                    <div class="edit-box" ref="editBox" @blur="editBlur" :contenteditable="state==0 || state==1 || state==2?'plaintext-only':false"
                      @input="checkLength"></div>
                    <div class="num">{{length}}/1000</div>
                    <div class="link-wraper">
              
                      <div class="file-list" v-if="ruform.AttachmentInfoList.length">
                        <div class="file-item" v-for="(item, index) in ruform.AttachmentInfoList" :key="index">
                          <div class="type">
                            <span v-if="item.Type == 1">【图片】</span>
                            <span v-if="item.Type == 2">【链接】</span>
                            <span v-if="item.Type == 3">【小程序】</span>
                          </div>
                          <div class="txt">：{{item.LinkName}}</div>
                          <div class="control">
                            <div class="edit" v-if="item.Type != 1" @click="handleEditFile(item, index)">
                              <i class="el-icon-edit"></i>
                            </div>
                            <el-upload :action="imgApi" v-else :before-upload="beforeUpload" :disabled="state==3"
                              :on-success="(res, file)=>{handleUploadSuccess(res, file, index)}"
                              accept="image/png,image/jpeg,image/jpg,image/gif" :show-file-list="false">
                              <div class="edit">
                                <i class="el-icon-edit"></i>
                              </div>
                            </el-upload>
                            <div class="close" @click="handleDeleteFile(index)">
                              <i class="el-icon-close"></i>
                            </div>
                          </div>
                        </div>
                      </div>
              
                      <el-popover placement="top" v-model="showPop" width="200" trigger="click"
                       v-if="ruform.AttachmentInfoList.length < 8 && (state==0 || state==1 || state==2)">
                        <div class="control-wraper">
                          <el-upload :action="imgApi" :before-upload="beforeUpload"
                            :on-success="(res, file)=>{handleUploadSuccess(res, file, -1)}"
                            accept="image/png,image/jpeg,image/jpg,image/gif" :show-file-list="false">
                            <div class="item">
                              <img src="@/assets/img/icon-tupian.png">
                              <div>图片</div>
                            </div>
                          </el-upload>
                          <div class="item" @click="handleAddLink">
                            <img src="@/assets/img/icon-lianjie.png">
                            <div>链接</div>
                          </div>
                          <div class="item" @click="handleAddMiniProgram">
                            <img src="@/assets/img/icon-xiaochengxu.png">
                            <div>小程序</div>
                          </div>
                        </div>
                        <el-link type="primary" slot="reference">+添加图片/链接/小程序
                        </el-link>
                      </el-popover>
                    </div>
                  </div>
                  <div class="tips-box" style="margin-top:20px;max-width: 800px;">
                    <div class="tit">提示：</div>
                    <div class="cont">
                      <div>1.欢迎语内容最多可发送1条文字消息和8个附件</div>
                      <div>2.文本内容不可为空；若添加多个附件（图片/链接/小程序），客户将收到多条消息</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>
            <div style="margin-left:20px">
              <div class="grayFont" style="color:#909399;font-size:14px">图片示例：</div>
              <img :src="imgUrl+'/image/rosepowder-detailRight.png'" style="width:300px;margin-top:5px" alt="">
            </div>
          </div>
          <div v-if="ruform.IsOpenActivityRights&&ruform.IsOpenWelcome">
            <el-form-item label="领取链接：">
              <div class = "grayFont">活动权益已开启，请完成领取链接配置。该链接将跟随欢迎语一同发放，客户点击链接进入领取权益
                <el-popover placement="bottom" trigger="hover">
                  <img :src="imgUrl+'/image/rosepowder-edit-popover.png'" style="width:200px" alt="">
                  <el-button slot="reference" type="text">查看示例</el-button>
                </el-popover>
              </div>
              <el-form-item label="链接封面：" prop="ReceiveAttachmentInfo.ImgUrl" :rules="rules.ImgUrl">
                <div style="display:flex">
                  <el-upload :action="imgApi" :before-upload="beforeUpload2" :on-success="handleUploadSuccess2" :disabled="state==3"
                  accept="image/*" :show-file-list="false" list-type="picture-card" class="link-upload">
                    <img v-if="ruform.ReceiveAttachmentInfo.ImgUrl" :src="imgUrl+ruform.ReceiveAttachmentInfo.ImgUrl" style="width:100%;height:100%" alt="">
                    <i v-else class = "el-icon-plus"></i>
                  </el-upload>
                  <div class = "flexCol" style="justify-content:space-between;margin-left:15px;">
                    <div class = "grayFont" style="width:200px">
                      建议图片宽高比5:4,宽高比不匹配时,图片将被压缩或拉伸以铺满画面;大小请控制在1M以内
                    </div>
                    <div>
                      <el-button :disabled="state==3" type="text" @click="ruform.ReceiveAttachmentInfo.ImgUrl = ''">清空图片</el-button>
                      <el-button :disabled="state==3" type="text" @click="ruform.ReceiveAttachmentInfo.ImgUrl = '/image/rosepowder-detail-defaultImg.png'">使用默认图片</el-button>
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="链接描述：" style="margin-top:20px">
                <el-input v-model="ruform.ReceiveAttachmentInfo.Description" placeholder="请输入链接描述，最多20个字" :disabled="state==3"
                 maxlength="20" style="width:300px"></el-input>
                <div class ="grayFont">若未配置描述，将使用系统默认文案：快来领取你的专属福利啦~</div>
              </el-form-item>
            </el-form-item>
            <el-form-item label="领取成功跳转至：" prop="ReceiveAttachmentInfo.LinkUrl" :rules="rules.LinkUrl">
              <el-button type="text" @click="shopVisible=true"  :disabled="state==3">
                <span v-if="!ruform.ReceiveAttachmentInfo.LinkName">选择页面链接</span>
                <span v-else>{{ruform.ReceiveAttachmentInfo.LinkName}}</span>
                <i class = "el-icon-arrow-down"></i>
              </el-button>
            </el-form-item>
          </div>
        </div>
      </el-card>
    </el-form>
    <el-card class="bottomCard" style="text-align:center;z-index:999">
      <el-button @click="$router.go(-1)" v-if="state!=3">取消</el-button>
      <el-button @click="$router.go(-1)" v-else>返回</el-button>
      <el-button v-if="state!=3" type="primary" :disabled="state==3" :loading="pageloading" @click="save('ruform')">保存</el-button>
    </el-card>

    <!-- 选择员工 -->
    <choosestaff :choosestaffshow="choosestaffshow" :chooseList="ruform.EmployeeList" :state="state"
      @getChooseList="getChooseList" @close="choosestaffshow=false"></choosestaff>

    <!-- 选择优惠券 -->
		<couponsdialog :visible.sync="couponsdialogshow" @confirm="getcouponslist" @close="couponsdialogshow = false"
			:thelist="ruform.QyWeixinRiseFansMarketingActivityRightsList"></couponsdialog>
      

    <el-dialog title="选择活动" :visible.sync="selectactiveDialog" width="1100px" class="dialog">
			<turntableActivities :visible.sync="selectactiveDialog"
				:checkShowId="(ruform.QyWeixinRiseFansMarketingActivityRightsList&&ruform.QyWeixinRiseFansMarketingActivityRightsList.length)?
        ruform.QyWeixinRiseFansMarketingActivityRightsList[0].RelationId:0"	:selectActivedata="ruform.QyWeixinRiseFansMarketingActivityRightsList"
         @getActivetList="getActivetList" v-if="selectactiveDialog">
			</turntableActivities>
		</el-dialog>

    <!-- 链接 -->
    <link-pop :visible="miniVisible" :defaultData="editData" @close="miniVisible=false" @change="miniChange"></link-pop>
    
    <!-- 小程序 -->
    <miniprogram-card :visible="cardVisible" :defaultData="editData" @close="cardVisible=false" @change="cardChange"></miniprogram-card>

    <linkModal :showLiveTab="true" :visible="shopVisible" @change="dataChange" @cancel="shopVisible=false" key="1"></linkModal>
  </div>
</template>

<script>
import choosestaff from "./components/choosestaff"
import couponstable from "@/views/smartmarket/components/couponstable"
import couponsdialog from "@/views/smartmarket/components/couponsdialog"
import activityTable from '@/views/smartmarket/components/activityTable.vue';
import turntableActivities from '@/views/smartmarket/components/turntableActivities.vue';
import linkPop from '@/views/qyCustomer/components/miniprogramLink.vue';
import miniprogramCard from './components/miniprogramCard.vue';
import linkModal from '@/views/components/linkModal';

import config from '@/config/index';

import {
  qyWeixinRiseFansMarketingsave,
  qyWeixinRiseFansMarketingdetail
}from "@/api/sv3.0.0"
export default {
  components:{
    choosestaff,
    couponstable,
    couponsdialog,
    activityTable,
    turntableActivities,
    linkPop,
    miniprogramCard,
    linkModal
  },
  data () {
    var checkActivityEndTime = (rule, value, callback) => {
      // console.log(new Date(new Date(new Date().toLocaleDateString()).getTime()))
      if(!this.ruform.ActivityStartTime){
        callback(new Error('请完善开始时间'))
      }else if(!this.ruform.ActivityEndTime){
        callback(new Error('请完善结束时间'))
      }else if(new Date(this.ruform.ActivityStartTime).getTime()<new Date(new Date(new Date().toLocaleDateString()).getTime()) && this.state!=2&&this.state!=3){
        callback(new Error('开始时间不可选择昨天及以前的日期'))
      }else if(new Date(this.ruform.ActivityStartTime).getTime()>new Date(this.ruform.ActivityEndTime).getTime()){
        callback(new Error('开始时间不可大于结束时间'))
      }else{
        callback()
      }
    };
    var checkRiseFansTarget = (rule, value, callback) => {
      if(value){
        if(value <1 || value >9999999 || value !=parseInt(value)){
          callback(new Error('数量请设置在1~9999999之间，整数'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    var checkActivityRightsType = (rule, value, callback) => {
      if(this.ruform.IsOpenActivityRights){
        if(!value){
          callback(new Error('请选择活动权益'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    var checkQyWeixinRiseFansMarketingActivityRightsList = (rule, value, callback) => {
      // console.log(this.ruform.ActivityRightsType)
      if(this.ruform.ActivityRightsType){
        if(!value || !value.length){
          callback(new Error(this.ruform.ActivityRightsType==1?'请选择赠送的优惠券':'请选择抽奖活动'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    var checkAttachmentInfoList = (rule, value, callback) => {
      if(this.ruform.IsOpenWelcome){
        // console.log(this.ruform.Welcome)
        if(!this.ruform.Welcome){
          callback(new Error('欢迎语文本内容不可为空'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    return {
      imgApi: config.UPLOAD_IMG,
      imgUrl:config.IMG_BASE,
      pageloading:false,
      state:0,
      noedit:false,
      ruform:{
        Id:0,
        ActivityName:'',
        ActivityStartTime:'',
        ActivityEndTime:'',
        EmployeeList:[],
        EmployeeIdList:[],
        RiseFansTarget:'',
        IsOpenActivityRights:false,
        ActivityRightsType:null,
        QyWeixinRiseFansMarketingActivityRightsList:[],
        Welcome:'',
        AttachmentInfoList:[],
        ReceiveAttachmentInfo:{
          ImgUrl:'/image/rosepowder-detail-defaultImg.png',
          Description:'快来领取你的专属福利啦~',
          LinkUrl:'',
          LinkName:'',
        },
      },
      rules:{
        ActivityName:[
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
        ActivityEndTime:[
          { validator: checkActivityEndTime, trigger: 'blur' }
        ],
        EmployeeList :[
          { required: true, message: '请选择适用员工', trigger: 'blur' },
        ],
        RiseFansTarget:[
          { validator: checkRiseFansTarget, trigger: 'blur' }
        ],
        ActivityRightsType:[
          { validator: checkActivityRightsType, trigger: 'blur' }
        ],
        QyWeixinRiseFansMarketingActivityRightsList:[
          { validator: checkQyWeixinRiseFansMarketingActivityRightsList, trigger: 'blur' }
        ],
        Welcome:[
          { validator: checkAttachmentInfoList, trigger: 'blur' }
        ],
        ImgUrl:[
          { required: true, message: '请上传领取链接封面图', trigger: 'blur' },
        ],
        LinkUrl:[
          { required: true, message: '请选择领取成功后跳转的页面', trigger: 'blur' },
        ]
      },
      pickerOptions:{
        disabledDate:(v)=>{
          return v.getTime() < new Date().getTime()-1000*60*60*24
        }
      },
      choosestaffshow:false,

      couponsdialogshow:false,

      selectactiveDialog:false,

      length:0,
      fileList:[],
      showPop:false,
      miniVisible:false,
      cardVisible:false,
      editData: {},
      shopVisible:false,

    }
  },
  created () {
    if(Number(this.$route.query.Id)){
      this.getInfo()
    }
  },
  methods: {
    ActivityRightsTypeChange(){
      this.ruform.QyWeixinRiseFansMarketingActivityRightsList = []
      this.$refs.ruform.validateField('QyWeixinRiseFansMarketingActivityRightsList')
    },
    dataChange(msg){
      this.ruform.ReceiveAttachmentInfo.LinkName  = msg.text
      this.ruform.ReceiveAttachmentInfo.LinkUrl = this.geturl(msg).url
    },
    geturl(record){
      let data = {
        url:'',
        type:'',
      }
      if (record.type == "classify") {
        data.url = 'pages/productGroup/productGroup?id=' + record.data.Id
        data.type = 1
      } else if (record.type == "functional") {
        // 全部商品
        data.type = 2
        if (record.data.id == 1) {
          data.url = 'pages/index/index'
        } else if (record.data.id == 2) {
          data.url = 'pages/allProduct/allProduct'
        } else if (record.data.id == 3) {
          // 个人中心
          data.url = 'pages/personalCenter/mine/mine'
        } else if (record.data.id == 4) {
          // 购物车
          data.url = 'pages/shoppingCart/shoppingCart'
        } else if (record.data.id == 5) {
          // 积分中心
          data.url = 'pages/pointsCenter/pointsCenter'
        } else if (record.data.id == 6) {
          // 每日签到
          data.url = 'pages/userSign/userSign'
        } else if (record.data.id == 7) {
          // 客服
          data.url = 'pages/customerService/customerService'
        } else if (record.data.id == 8) {
          // 订单列表
          data.url = 'pages/order/myOrder/myOrder'
        } else if (record.data.id == 9) {
          // 分销礼包
          data.url = 'pages/invitation/invitationCard/invitationCard'
        }else if (record.data.id == 10) {
          // 成为会员
          data.url = 'pageA/pages/openSuccess/openSuccess'
        }else if (record.data.id == 11) {
          // 助力直播列表
          data.url = 'pageA/pages/live/live'
        }else if (record.data.id == 12) {
          // 扫码购
          data.url = 'pageA/pages/scan/scan'
        }else if (record.data.id == 13) {
          // 充值中心
          data.url = 'pageA/pages/balanceRecharge/balanceRecharge'
        }else if (record.data.id == 14) {
          // 群分享
          data.url = 'pageA/pages/groupShare/groupShare'
        }else if (record.data.id == 15) {
          // 全部信息页
          data.url = 'pageA/pages/information/information'
        }else if (record.data.id == 16) {
          // 短视频带货
          data.url =  'pageC/pages/shortVideos/record'
        }else if (record.data.id == 17) {
          // 积分码
          data.url = 'pageA/pages/receive-point/receive-point'
        }else if (record.data.id == 18) {
          // 舌诊
          data.url = 'pageC/pages/tongueDiagnosis/home'
        }else if (record.data.id == 19) {
          // 企店即创主页
          data.url = 'pageC/pages/AIGC/home'
        }
      } else if (record.type == "product") {
        data.url = 'pages/detail/detail?id=' + record.data.Id
        data.type = 3
      } else if (record.type == "custom") {
        data.type = 4
        data.url = 'pages/cusPage/cusPage?id=' + record.data.Id
      }else if (record.type == 'truntable'){
        data.type = 6
        data.url = 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
      } else if (record.type == 'live'){
        data.type = 5
        data.url = 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
      }else if (record.type == 'coupon'){
        data.type = 7
        data.url = 'pages/couponCenter/receive/receive?id='+record.data.Id+'&uid='+(record.data.uid || '')
      }else if (record.type == 'help') {
        data.type = 8
        data.url = 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
      }else if (record.type == 'group') {
        data.type = 9
        data.url = 'pages/groupDetail/groupDetail?id=' + record.data.ProductId + '&fullId=' + record.data.ActivityFullId
      }else if (record.type == 'questionnaire') {
        data.type = 10
        data.url = 'pageA/pages/questionnaire/questionnaire?&referer=2&id=' + record.data.Id;
      }else if (record.type == 'taketest') {
        data.type = 11
        data.url = `pageC/pages/goodnessTest/home?id=` + record.data.Id
      }else if (record.type == 'contentgroup') {
        data.type = 12
        data.url = `pageA/pages/information/informationGroup?id=` + record.data.Id;
      }else if (record.type == 'singlecontent') {
        data.type = 13
        data.url = `pageA/pages/information/detail?id=` + record.data.Id;
      }else if (record.type == 'helpPro') {
        data.type = 14
        data.url = `pageC/pages/helpPro/helpPro?id=` + record.data.ProductId + '&activityId=' + record.data.ActivityFullId;
      }
      return data
    },
    handleUploadSuccess2(file){
      // console.log(file)
      this.ruform.ReceiveAttachmentInfo.ImgUrl = file[0]
    },
    beforeUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (!isLt2M) {
        this.$message.error('图片大小请控制在1M以内')
        return false
      }
      return true;
    },
    filterlabel(e){
			// console.log(e)
				let str  = JSON.parse(JSON.stringify(e))
				let newe = str.replace(/<[^<>]+>/g,'')
				// console.log(newe)
				return newe
    },
    handleDeleteFile(index) {
      if(this.state==3) return
      this.ruform.AttachmentInfoList.splice(index, 1)
    },
    miniChange(record) {
				
      var obj = {
        Type: 2, //2 链接
        LinkName: record.name,
        ImgUrl: record.imgUrl,
        LinkUrl: record.url,
        Description: record.desc
      }
      
      if (this.editIndex == -1){
        this.ruform.AttachmentInfoList.push(obj)
      }else{
        this.ruform.AttachmentInfoList.splice(this.editIndex, 1, obj)
      }
      
    },
    cardChange(record) {
      
      var obj = {
        Type: 3, //3 小程序
        LinkName: record.name,
        LinkUrl: record.url,
        Description: record.desc,
        ImgUrl: record.imgUrl,
      }
      
      if (this.editIndex == -1){
        this.ruform.AttachmentInfoList.push(obj)
      }else{
        this.ruform.AttachmentInfoList.splice(this.editIndex, 1, obj)
      }
      
    },
    handleAddLink(){
      this.editIndex = -1;
      this.miniVisible = true;
      this.editData = {};
    },
    handleAddMiniProgram(){
      this.editIndex = -1;
      this.cardVisible = true;
      this.editData = {};
    },
    handleEditFile(record, index){
      if(this.state==3) return
      this.editIndex = index;
      if (record.Type == 2){
        this.miniVisible = true;
        this.editData = record;
      }else if (record.Type == 3){
        this.cardVisible = true;
        this.editData = record;
      }
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error('图片大小请控制在2M以内')
        return false
      }
      return true;
    },
    handleUploadSuccess(res, file, index) {
      var obj = {
        Type: 1, //1 图片类型
        LinkName: file.name,
        ImgUrl: res[0]
      }
      
      if (index == -1){
        this.ruform.AttachmentInfoList.push(obj)
      }else{
        this.ruform.AttachmentInfoList.splice(index, 1, obj)
      }
      
      this.showPop = false;
    },
    insertWxNickName() {
      // var html = document.querySelector('.edit-box').innerHTML;
      document.querySelector('.edit-box').innerHTML += ' <span style="color: #409EFF;">#客户微信昵称#</span> ';
      this.length = this.filterlabel(document.querySelector('.edit-box').innerText).length;
    },
    insertEmployeeName() {
      // var html = document.querySelector('.edit-box').innerHTML;
      document.querySelector('.edit-box').innerHTML += ' <span style="color: #409EFF;">#员工姓名#</span> ';
      this.length = this.filterlabel(document.querySelector('.edit-box').innerText).length;
    },
    checkLength() {
      // var html = document.querySelector('.edit-box').innerText;
      // html = html.replace('#客户微信昵称#', '').replace('#员工姓名#', '');
      this.length = this.filterlabel(document.querySelector('.edit-box').innerText).length;
    },
    editBlur() {
      var html = document.querySelector('.edit-box').innerHTML;
      if (html.length > 1000) {
        document.querySelector('.edit-box').innerHTML = html.substr(0, 1000);
      }
      this.ruform.Welcome = html
      this.length = this.filterlabel(document.querySelector('.edit-box').innerHTML).length
      // console.log(html)
    },
    getActivetList(data) {
      // console.log(data,'123')
      if (!data.Id) {
        this.$message.error('请选择活动')
      } else {
        let msg = JSON.parse(JSON.stringify(data))
        let oldlist = JSON.parse(JSON.stringify(this.$refs.activityTable.returnList()))
        // console.log(msg,'1')
        msg.Type = 2
        msg.RelationId = data.Id
        if(oldlist[0]&&oldlist[0].Id==msg.Id){
          msg.GiftCount = oldlist[0].GiftCount||1
          msg.QyWeixinRiseFansMarketingActivityRightsId = oldlist[0].QyWeixinRiseFansMarketingActivityRightsId||0
        }else{
          msg.GiftCount = 1
          msg.QyWeixinRiseFansMarketingActivityRightsId = 0
        }
        this.ruform.QyWeixinRiseFansMarketingActivityRightsList = []
        this.ruform.QyWeixinRiseFansMarketingActivityRightsList.push(msg)
        // console.log(msg.Id,'2')
        this.selectactiveDialog = false;
        this.$nextTick(()=>{
          this.$refs.activityTable.validForm()
          this.$refs.ruform.clearValidate('QyWeixinRiseFansMarketingActivityRightsList')
        })
      }
    },
    getcouponslist(list){
      if (list && list.length) {
        let oldlist = JSON.parse(JSON.stringify(this.$refs.couponstable.returnList()))
        this.ruform.QyWeixinRiseFansMarketingActivityRightsList = list.map((v) => {
          let data = oldlist.find(x=>x.Id==v.Id)
          if(data){
            v.QyWeixinRiseFansMarketingActivityRightsId = data.QyWeixinRiseFansMarketingActivityRightsId
            v.GiftCount = data.GiftCount || 1
          }else{
            v.QyWeixinRiseFansMarketingActivityRightsId = 0
            v.GiftCount = 1
          }
          v.RelationId = v.Id
          v.Type = 1
          return v
        })
        this.couponsdialogshow = false
        this.$nextTick(()=>{
          this.$refs.couponstable.validForm()
          this.$refs.ruform.clearValidate('QyWeixinRiseFansMarketingActivityRightsList')
        })
      } else {
        this.$message.error('请选择赠送的优惠券')
      }
    },
    getChooseList(list){
      this.ruform.EmployeeList = list
    },
    delshop(list){
      this.ruform.QyWeixinRiseFansMarketingActivityRightsList = list
    },
    save(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if(this.ruform.IsOpenActivityRights){
            let theRefs = this.ruform.ActivityRightsType==1?'couponstable':'activityTable'
            try{
              let res = await this.$refs[theRefs].validForm()
              if(res&&res.length){
                this.ruform.QyWeixinRiseFansMarketingActivityRightsList = res.map((v)=>{
                    v.RelationId = v.Id
                  return v
                })
                this.tosave()
              }else{
                this.err()
              }
            }catch{
              this.err()
            }
            return 
          }
          this.tosave()
        } else {
          this.err()
        }
      })
    },
    err(){
      this.$message.error('请完善配置项')
      this.$nextTick(()=>{
        let errdiv = document.getElementsByClassName('is-error')
        errdiv[0].scrollIntoView({
          block:'center',
          behavior:'smooth'
        })
      })
      return false;
    },
    async tosave(){
      this.pageloading = true
      try{
        let data = JSON.parse(JSON.stringify(this.ruform))
        data.ReceiveAttachmentInfo.Description = data.ReceiveAttachmentInfo.Description?data.ReceiveAttachmentInfo.Description:'快来领取你的专属福利啦~'
        data.EmployeeList = data.EmployeeList.map(v=>v.Id)
        if(!data.IsOpenActivityRights){
          data.ActivityRightsType = null
          data.QyWeixinRiseFansMarketingActivityRightsList = []
        }
        if(!data.IsOpenWelcome){
          data.Welcome = ''
          data.AttachmentInfoList = []
        }else{
          data.Welcome = this.filterlabel(document.querySelector('.edit-box').innerHTML)
        }
        if(!data.IsOpenWelcome || !data.IsOpenActivityRights){
          data.ReceiveAttachmentInfo = {
            ImgUrl:'',
            LinkUrl:'',
            LinkName:'',
            Description:'',
          }
        }
        if(!data.ReceiveAttachmentInfo.ImgUrl){
          data.ReceiveAttachmentInfo.ImgUrl = '/image/rosepowder-detail-defaultImg.png'
        }
        
        // console.log(data)
        let res = await qyWeixinRiseFansMarketingsave(data)
        if(res.IsSuccess){
          this.$message.success('保存成功')
          this.$router.go(-1)
        }
      }finally{
        this.pageloading = false
      }
    },

    async getInfo(){
      this.pageloading = true
      try{
        let data = {
          Id:this.$route.query.Id
        }
        let res = await qyWeixinRiseFansMarketingdetail(data)
        if(res.IsSuccess){
          let ruform = res.Result
          ruform.QyWeixinRiseFansMarketingActivityRightsList.map((v)=>{
            if(ruform.ActivityRightsType==1){
              v = Object.assign(v,v.CouponInfo)
            }else{
              v = Object.assign(v,v.WheelSurfInfo)
            }
            v.Id = v.RelationId
            return v
          })

          // console.log(ruform.QyWeixinRiseFansMarketingActivityRightsList)
          this.state = ruform.State
          if(this.$route.query.IsCopy){
            ruform.Id = 0
            this.state = 0
            ruform.ActivityStartTime=''
            ruform.ActivityEndTime=''
          }
          this.ruform = ruform
          // console.log(this.ruform)
          if(ruform.IsOpenWelcome){
            this.$nextTick(()=>{
              document.querySelector('.edit-box').innerHTML = this.ruform.Welcome
                .replace(/#客户微信昵称#/g, ' <span style="color: #409EFF;">#客户微信昵称#</span> ')
                .replace(/#员工姓名#/g, ' <span style="color: #409EFF;">#员工姓名#</span> ');
			        this.length = this.filterlabel(document.querySelector('.edit-box').innerHTML).length
            })
          }
        }
      }finally{
        this.pageloading = false
      }
    },
  }
}
</script>

<style lang = "less" scoped>
.titFont{
  font-size: 16px;
  color: #303133;
  font-weight: bold;
}
.flexRow{
  display: flex;
  align-items: center;
}
.flexCol{
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.flexcontent{
  justify-content: center;
  align-items: center;
}
.linkFont{
  font-size:12px;margin:0px;padding:0px;line-height:1;
  margin-top: -40px;
}
.grayFont{
  color: #999999;
  font-size: 12px;
  line-height: 1.5;
}
.haveStar ::v-deep .el-form-item__label:before{
  content: '*';
  color: #F56C6C;
  margin-right: 4px;
}
.bottomCard{
    position: fixed;
    bottom: 0px;
    height: 80px;
    left: 10px;
    right: 0px;
    z-index: 999;
  }
  .edit-box {
    height: 230px;
    padding: 15px;
    box-sizing: border-box;
    overflow: auto;
  }
.edit-wraper {
  border: 1px solid #ddd;
  background-color: #FBFDFF;
  line-height: 18px;
  border-radius: 4px;
  
  position: relative;

  .title {
    padding: 15px 15px;
    display: flex;
    border-bottom: 1px dashed #bbb;
  }

  .num {
    position: absolute;
    right: 10px;
    top: 254px;
    color: #999;
  }

  .link-wraper {
    padding: 15px;
    border-top: 1px solid #ddd;


  }

  .file-list {
    overflow: hidden;
    .file-item {
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-bottom: 10px;
      // background: #cccbcb;
      // padding: 5px 10px;
      color: #666;
      font-size: 12px;

      >img {
        flex: 0 0 auto;
        width: 20px;
        margin-right: 5px;
      }
      
      .type{
        flex: 0 0 auto;
        width: 60px;
      }

      .txt {
        flex: 1 1 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // max-width: 80%;
      }
      .control{
        flex: 0 0 auto;
        margin-left: 10px;
        display: flex;
        
      }
      .edit {
        cursor: pointer;
        font-size: 16px;
      
        display: flex;
        align-items: center;
      }
      .close {
        margin-left: 5px;
        cursor: pointer;
        font-size: 16px;

        display: flex;
        align-items: center;
      }
    }
  }
}
.control-wraper {
  display: flex;

  .item {

    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;

    img {
      width: 24px;
      margin-bottom: 4px;
    }
  }
}
.tips-box {
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #409EFF;
  background-color: #FBFDFF;
  font-size: 14px;
  line-height: 24px;
  color: #606266;

  display: flex;

  .tit {
    flex: 0 0 auto;
    width: 50px;
  }

  .cont {
    flex: 1 1 auto;
    overflow: hidden;
  }
}
.Employeetag{
  position: relative;
    top: 5px;
}
</style>
<style>
 .link-upload .el-upload--picture-card{
   width: 150px;
   height: 120px;
   display: flex;
   align-items: center;
   justify-content: center;
 }
</style>